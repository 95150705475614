import { render, staticRenderFns } from "./VolLaluLintasSection.vue?vue&type=template&id=464f3356&scoped=true"
import script from "./VolLaluLintasSection.vue?vue&type=script&lang=js"
export * from "./VolLaluLintasSection.vue?vue&type=script&lang=js"
import style0 from "./VolLaluLintasSection.vue?vue&type=style&index=0&id=464f3356&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "464f3356",
  null
  
)

export default component.exports