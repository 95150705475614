<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    },
  },
  methods: {
    formattedAverage(value) {
      const resultValue = Number(value);
      if (!isNaN(resultValue)) {
        const roundedValue = resultValue.toFixed(0);
        return new Intl.NumberFormat('id-ID').format(roundedValue);
      }
      return value;
    },
    formattedNumber(value) {
      const resultValue = Number(value);
      if (!isNaN(resultValue)) {
        const roundedValue = resultValue.toFixed(0);
        return new Intl.NumberFormat('id-ID').format(roundedValue);
      }
      return value;
    },
    scrollLeft() {
      const container = this.$refs.scrollContainer.$el.querySelector('.v-data-table__wrapper');
      if (container) {
        container.scrollBy({
          left: -200,
          behavior: 'smooth'
        });
      }
    },
    scrollRight() {
      const container = this.$refs.scrollContainer.$el.querySelector('.v-data-table__wrapper');
      if (container) {
        container.scrollBy({
          left: 200,
          behavior: 'smooth'
        });
      }
    },
    // calculateTotal(groupDetails) {
    //   // Menghitung jumlah total dari semua golongan
    //   return groupDetails.reduce((sum, detail) => sum + detail.lhr_weighed, 0);
    // },
    // calculateOverallJumlah(details) {
    //   // Menghitung jumlah total keseluruhan
    //   return details.reduce((sum, detail) => sum + detail.total, 0);
    // },
    // calculateOverallRerata(details) {
    //   // Menghitung rata-rata keseluruhan
    //   const totalSum = details.reduce((sum, detail) => sum + detail.total, 0);
    //   return details.length > 0 ? (totalSum / details.length).toFixed(2) : 0;
    // }
  }
};
</script>

<template>
  <div class="mb-8">
    <div class="d-flex align-center mb-3">
      <h3 class="text--black" style="font-size: 20px; font-weight: 500;">{{ data.name }}</h3>
      <span class="ml-3" style="font-size: 16px; font-weight: 500; color: #E16162;">{{ data.length }} Km</span>
    </div>

    <div class="d-flex justify-space-between">
      <v-simple-table ref="scrollContainer" dense class="elevation-0 route-table">
        <template v-slot:default>
          <thead>
            <tr class="mint-background">
              <th class="text-center black--text py-3 first-col" style="background-color: #ABD1C6 !important; border-right: none; font-size: 14px; font-weight: 500;">GOL</th>
              <th class="text-center black--text" style="font-size: 14px; font-weight: 500;" 
                  v-for="detail in data.details" :key="detail.date">
                {{ new Date(detail.date).toLocaleDateString('en-GB', { day: '2-digit', month: 'short' }) }}
              </th>

              <th class="text-center white--text last-col rerata-col" style="background-color: #004643; font-size: 14px; font-weight: 500;">RERATA</th>
              <th class="text-center white--text last-col jumlah-col" style="background-color: #004643; font-size: 14px; font-weight: 500;">JUMLAH</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="golongan in 5" :key="`golongan-${golongan}`">
              <td class="text-center black--text first-col" style="font-size: 14px; font-weight: 500;">{{ golongan }}</td>
              <td
                class="text-center black--text table-cell"
                style="font-size: 14px; font-weight: 500;"
                v-for="detail in data.details"
                :key="`golongan-${golongan}-${detail.date}`"
              >
                {{ formattedNumber(detail.group_value?.find(d => d.group === golongan)?.lhr_weighed || 0) }}
              </td>
              <td
                class="text-center black--text last-col rerata-col"
                style="background-color: #E8E4E6 !important; font-size: 14px; font-weight: 500;"
              >
                {{ formattedAverage(data.summary_per_groups?.find(d => d.group === golongan)?.average || 0) }}
              </td>
              <td
                class="text-center black--text last-col jumlah-col"
                style="background-color: #E8E4E6 !important; font-size: 14px; font-weight: 500;"
              >
                {{ formattedNumber(data.summary_per_groups?.find(d => d.group === golongan)?.total || 0) }}
              </td>
            </tr>
            <tr class="total-row">
              <td class="text-center white--text first-col" style="background-color: #004643 !important; font-size: 14px; font-weight: 500;">TOTAL</td>
              <td
                class="text-center table-cell"
                style="background-color: #E8E4E6 !important; font-size: 14px; font-weight: 500;"
                v-for="detail in data.details"
                :key="`total-${detail.date}`"
              >
                {{ formattedNumber(detail.total_lhr_weighed) }}
              </td>
              <td
                class="text-center last-col rerata-col"
                style="background-color: #B2AFB1 !important; font-size: 14px; font-weight: 500;"
              >
                {{ formattedNumber(data.summary_all_days.avg_total_lhr_weighed) }}
              </td>
              <td
                class="text-center last-col jumlah-col"
                style="background-color: #B2AFB1 !important; font-size: 14px; font-weight: 500;"
              >
                {{ formattedNumber(data.summary_all_days.sum_total_lhr_weighed) }}
              </td>
            </tr>
            <tr class="total-row">
              <td class="text-center white--text first-col" style="background-color: #004643 !important; font-size: 14px; font-weight: 500;">LHR<br/>Tertimbang</td>
              <td
                class="text-center table-cell"
                style="background-color: #E8E4E6 !important; font-size: 14px; font-weight: 500;"
                v-for="detail in data.details"
                :key="`total-${detail.date}`"
              >
                {{ formattedNumber(detail.total_lhr_weighed_converted) }}
              </td>
              <td
                class="text-center last-col rerata-col"
                style="background-color: #B2AFB1 !important; font-size: 14px; font-weight: 500;"
              >
                {{ formattedNumber(data.summary_all_days.avg_total_lhr_weighed_converted) }}
              </td>
              <td
                class="text-center last-col jumlah-col"
                style="background-color: #B2AFB1 !important; font-size: 14px; font-weight: 500;"
              >
                {{ formattedNumber(data.summary_all_days.sum_total_lhr_weighed_converted) }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <div class="d-flex flex-column justify-center items-center pl-4">
        <v-btn
          class="rounded-lg pa-6"
          style="border: 1px solid #004643;"
          icon
          @click="scrollRight"
        >
          <v-icon color="#004643">mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn
          class="rounded-lg mt-2 pa-6"
          style="border: 1px solid #004643;"
          icon
          @click="scrollLeft"
        >
          <v-icon color="#004643">mdi-chevron-left</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<style scoped>
.route-table {
  width: 100%;
  overflow-x: auto;
}

.table-cell {
  border-right: 1.5px solid #E8E4E6;
}

.first-col {
  background-color: white !important;
  position: sticky;
  left: 0;
  z-index: 2;
  border-right: 1.5px solid #E8E4E6;
}

.last-col {
  position: sticky;
  z-index: 1;
}

.rerata-col {
  right: 92px; /* Adjust this value based on the width of the JUMLAH column */
}

.jumlah-col {
  right: 0;
}

.mint-background {
  background-color: #ABD1C6 !important;
}

.total-row {
  background-color: #F5F5F5;
}

/* Override Vuetify default styles */
::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  font-weight: 500;
  white-space: nowrap;
}

::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 14px;
  height: 48px;
}
</style>
