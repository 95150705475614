<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      revenueData: [
        { name: "BCA", amount: "Rp. 999.000.000", color: "#ABB5D1" },
        { name: "BNI", amount: "Rp. 999.000.000", color: "#D1B7AB" },
        { name: "BRI", amount: "Rp. 999.000.000", color: "#D1CEAB" },
        { name: "Mandiri", amount: "Rp. 999.000.000", color: "#D1ABD1" },
        { name: "Tunai", amount: "Rp. 999.000.000", color: "#ABD1C6" },
      ],
    };
  },
  methods: {
    formattedAverage(value) {
      const resultValue = Number(value);
      if (!isNaN(resultValue)) {
        const roundedValue = resultValue.toFixed(0);
        return new Intl.NumberFormat('id-ID').format(roundedValue);
      }
      return value;
    },
    formattedNumber(value) {
      const resultValue = Number(value);
      if (!isNaN(resultValue)) {
        const roundedValue = resultValue.toFixed(0);
        return new Intl.NumberFormat('id-ID').format(roundedValue);
      }
      return value;
    },
    getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    getColorByType(type) {
      switch (type) {
        case "BCA":
          return "orange";
        case "BNI":
          return "teal";
        case "BRI":
          return "red";
        case "MDR":
          return "blue";
        case "CASH":
          return "purple";
        default:
          return "grey";
      }
    },
    scrollLeft() {
      const container = this.$refs.scrollContainer;
      if (container) {
        container.scrollLeft -= 200;
        container.behavior = "smooth";
      }
    },
    scrollRight() {
      const container = this.$refs.scrollContainer;
      if (container) {
        container.scrollLeft += 200;
        container.behavior = "smooth";
      }
    },
  },
};
</script>

<template>
  <div class="py-4 px-10">
    <v-card class="rounded-xl" outlined>
      <v-row no-gutters class="pa-5 text-center">
        <v-col class="text-uppercase my-auto">
          <h3 style="font-size: 14px; font-weight: 600">HPT</h3>
          <h1 style="font-size: 42px; font-weight: 500">
            {{ formattedNumber(data.total) }}
          </h1>
        </v-col>
      </v-row>
      <v-row no-gutters style="overflow: hidden">
        <v-col
          v-for="(item, index) in data.details"
          :key="index"
          class="pa-5 custom-border"
          :class="{ 'last-child': index === data.details.length - 1 }"
        >
          <div class="d-flex align-center">
            <v-avatar
              class="mr-3"
              size="10"
              :color="getColorByType(item.payment_method)"
            ></v-avatar>
            <div class="text-uppercase">
              <h5 style="font-size: 12px; font-weight: 600">
                {{ item.payment_name }}
              </h5>
              <h5 class="pr-3" style="font-size: 20px; font-weight: 500">
                {{ formattedNumber(data.total) }}
              </h5>
              <h5 style="font-size: 12px; font-weight: 400; color: #a3a3a3">
                Kendaraan
              </h5>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<style scoped>
.custom-border {
  border-top: 1px solid #e8e4e6;
  border-right: 1px solid #e8e4e6;
}

.custom-border.last-child {
  border-right: none;
}
</style>
