<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    scrollLeft() {
      const container = this.$refs.scrollContainer.$el.querySelector(
        ".v-data-table__wrapper"
      );
      if (container) {
        container.scrollBy({
          left: -200,
          behavior: "smooth",
        });
      }
    },
    scrollRight() {
      const container = this.$refs.scrollContainer.$el.querySelector(
        ".v-data-table__wrapper"
      );
      if (container) {
        container.scrollBy({
          left: 200,
          behavior: "smooth",
        });
      }
    },
    formattedAverage(value) {
      const resultValue = Number(value);
      if (!isNaN(resultValue)) {
        const roundedValue = resultValue.toFixed(0);
        return new Intl.NumberFormat('id-ID').format(roundedValue);
      }
      return value;
    },
    formattedNumber(value) {
      const resultValue = Number(value);
      if (!isNaN(resultValue)) {
        const roundedValue = resultValue.toFixed(0);
        return new Intl.NumberFormat('id-ID').format(roundedValue);
      }
      return value;
    },
  },

};
</script>

<template>
  <div class="mb-8">
    <h3 class="text--black pb-3" style="font-size: 18px; font-weight: 500">
      {{ data.gate_name }}
    </h3>
    <div class="d-flex justify-space-between" justify="center">
      <v-simple-table
        ref="scrollContainer"
        style="overflow-x: auto; width: 100%"
      >
        <template v-slot:default>
          <thead>
            <tr class="mint-background">
              <th
                class="text-center"
                v-for="(item, index) in data.data"
                :key="`${data.gate_code}-header-${index}`"
              >
                {{
                  new Date(item.date).toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "short",
                  })
                }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                class="table-cell text-center"
                v-for="(item, index) in data.data"
                :key="`${data.gate_code}-value-${index}`"
              >
                {{ formattedNumber(item.total) }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <div class="px-8" style="position: relative; z-index: 10">
        <v-btn
          class="rounded-lg"
          style="
            background-color: white;
            position: absolute;
            right: 0px;
            top: 0%;
            border: 1px solid #004643;
            height: 45px;
            width: 45px;
            padding-left: 10px;
            padding-right: 10px;
          "
          icon
          @click="scrollRight"
        >
          <v-icon style="color: #004643">mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn
          class="rounded-lg"
          style="
            background-color: white;
            position: absolute;
            right: 0px;
            bottom: 0%;
            border: 1px solid #004643;
            height: 45px;
            width: 45px;
            padding-left: 10px;
            padding-right: 10px;
          "
          icon
          @click="scrollLeft"
        >
          <v-icon style="color: #004643">mdi-chevron-left</v-icon>
        </v-btn>
      </div>
    </div>
    <div class="d-flex px-3 py-1">
      <div class="mr-8 d-flex">
        <h6
          class="mr-4 text-uppercase"
          style="font-size: 14px; font-weight: 500; color: #a3a3a3"
        >
          Jumlah
        </h6>
        <h6 class="text--black" style="font-size: 16px; font-weight: 500">
          {{ formattedNumber(data.total) }}
        </h6>
      </div>
      <div class="d-flex">
        <h6
          class="mr-4 text-uppercase"
          style="font-size: 14px; font-weight: 500; color: #a3a3a3"
        >
          Rerata
        </h6>
        <h6 class="text--black" style="font-size: 16px; font-weight: 500">
          {{ formattedAverage(data.average) }}
        </h6>
      </div>
    </div>
  </div>
</template>

<style scoped>
.table-cell {
  border-right: 1.5px solid #e8e4e6;
}

tr td:first-child {
  border-right: 1.5px solid #e8e4e6;
}

.table-cell:last-child {
  border-right: none;
}

.mint-background {
  background-color: #abd1c6 !important;
}
</style>
