<script>
// import MaintenanceDialogComponent from "@/components/MaintenanceDialogComponent";
import DateFilterComponent from "@/components/LayananTransaksi/DateFilterComponent.vue";
import TitleMenuComponent from "@/components/TitleMenuComponent";
import PendapatanComponent from "@/sections/LayananTransaksi/PendapatanSection";
import LHRTertimbangComponent from "@/sections/LayananTransaksi/LHRTertimbangSection";
import LHRPersegmenComponent from "@/sections/LayananTransaksi/LHRPersegmenSection";
import VolLaluLintasComponent from "@/sections/LayananTransaksi/VolLaluLintasSection";
import HPTComponent from "@/sections/LayananTransaksi/HPTSection";
import * as constant from "@/constants/constant";
import Cookies from "js-cookie";

export default {
  components: {
    // MaintenanceDialogComponent
    DateFilterComponent,
    TitleMenuComponent,
    PendapatanComponent,
    LHRTertimbangComponent,
    LHRPersegmenComponent,
    VolLaluLintasComponent,
    HPTComponent,
  },
  data() {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 2);
    const account = JSON.parse(Cookies.get("loginResponse"));

    return {
      account: account,
      dialogTitle: "Download",
      start_date: firstDayOfMonth.toISOString().split("T")[0],
      end_date: today.toISOString().split("T")[0],
      currentButton: 0,
      buttons: [
        {
          label: "Pendapatan",
          icon: "mdi-wallet",
          component: PendapatanComponent,
        },
        {
          label: "LHR Tertimbang",
          icon: "mdi-scale",
          component: LHRTertimbangComponent,
        },
        {
          label: "LHR Persegmen",
          icon: "mdi-chart-line",
          component: LHRPersegmenComponent,
        },
        {
          label: "Vol Lalu Lintas",
          icon: "mdi-traffic-light",
          component: VolLaluLintasComponent,
        },
        {label: "HPT", icon: "mdi-road", component: HPTComponent},
      ],
      selectedFile: null,
      downloadDialog: false,
      isDragging: false,
      uploadDialog: false,
      loading: false,
      previewDialog: false,
      pdfUrl: null,
      pdfBlob: null,
    };
  },
  computed: {
    constant() {
      return constant
    },
    activeComponent() {
      return this.buttons[this.currentButton].component;
    },
  },
  methods: {
    async uploadFile() {
      const formData = new FormData();
      formData.append("excel_file", this.selectedFile);

      const response = await this.$axios.post("/upload-att6", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      this.selectedFile = null;
      this.message = response.data.status;
      this.snackbar = true;
      this.dialog = false;
    },
    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave() {
      this.isDragging = false;
    },
    drop(e) {
      e.preventDefault();
      const files = e.dataTransfer.files;

      if (files.length > 0) {
        const file = files[0];
        if (file.name.endsWith(".xlsx")) {
          this.selectedFile = file;
          this.isDragging = false;
        }
      }
    },
    clearSelectedFile() {
      this.selectedFile = null;
      const inputElement = this.$refs.fileInput;
      inputElement.value = "";
    },
    onChange() {
      const inputElement = this.$refs.fileInput;
      if (inputElement.files.length > 0) {
        this.selectedFile = inputElement.files[0];
      }
    },

    updateStartDate(newDate) {
      if (this.start_date !== newDate) {
        this.start_date = newDate;
      }
    },
    updateEndDate(newDate) {
      if (this.end_date !== newDate) {
        this.end_date = newDate;
      }
    },
    setActiveButton(index) {
      this.currentButton = index;
    },
    showDownloadDialog() {
      this.loading = true;
      this.fetchDownloadData();
    },
    async fetchDownloadData() {
      try {
        const response = await this.$axios.get(`transactions/download`, {
          params: {
            from: this.start_date,
            to: this.end_date
          },
          responseType: "blob",
        });

        this.pdfBlob = response.data;
        this.pdfUrl = URL.createObjectURL(response.data);
        this.downloadDialog = false;
        this.previewDialog = true;
      } catch (error) {
        console.error("Error fetching PDF:", error);
      } finally {
        this.loading = false;
      }
    },
    downloadPdf() {
      if (this.pdfBlob) {
        const link = document.createElement("a");
        link.href = this.pdfUrl;
        link.download = `Laporan-${this.start_date}-${this.end_date}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
  },
  beforeDestroy() {
    if (this.pdfUrl) {
      URL.revokeObjectURL(this.pdfUrl);
    }
  },
};
</script>

<template>
  <div class="pa-0 ma-0">
    <div class="px-10 py-5">
      <TitleMenuComponent title="Layanan Transaksi"/>
    </div>
    <v-divider></v-divider>
    <div class="my-4 ps-10">
      <v-row no-gutters>
        <v-col align="left" class="my-auto" cols="8">
          <v-sheet>
            <v-btn
                v-for="(button, index) in buttons"
                :key="index"
                :class="{
                'text-capitalize text-body-2 rounded-xl': true,
                'white--text': currentButton === index,
                'black--text': currentButton !== index,
              }"
                :style="{
                backgroundColor:
                  currentButton === index ? '#004643' : '#E4EFEC',
              }"
                class="mr-4"
                depressed
                variant="outlined"
                @click="setActiveButton(index)"
            >
              <v-icon left>{{ button.icon }}</v-icon>
              <span
                  :class="{
                  'white--text': currentButton === index,
                  'black--text': currentButton !== index,
                }"
              >
                {{ button.label }}
              </span>
            </v-btn>
          </v-sheet>
        </v-col>
        <v-col align="right" class="my-auto" cols="4">
          <v-sheet style="background-color: transparent">
            <v-btn
                :disabled="this.account.scopes.includes(constant.SCOPES.view_transaction)
                && !(this.account.scopes.includes(constant.SCOPES.manage_transaction))"
                @click="uploadDialog = true"
                class="mr-4 text-capitalize text-body-2 text--black white rounded-xl"
                style=""
                variant="outlined"
                elevation="1"
            >
              <span class="text--black">Upload Ruas CTP</span>
              <v-icon right>mdi-upload</v-icon>
            </v-btn>
            <v-btn
                class="mr-4 text-capitalize text-body-2 white--text rounded-xl"
                style="background-color: #004643"
                depressed
                variant="outlined"
                @click="downloadDialog = true"
            >
              <span class="white--text">Download</span>
              <v-icon right>mdi-download</v-icon>
            </v-btn>
            <v-btn
                class="mr-4 text-capitalize text-body-2 text--black white rounded-xl"
                style=""
                variant="outlined"
                elevation="1"
                to="/layanan-transaksi/history"
            >
              <v-icon>mdi-history</v-icon>
            </v-btn>
          </v-sheet>
        </v-col>
      </v-row>
    </div>
    <v-divider></v-divider>
    <div>
      <component :is="activeComponent"></component>
    </div>

    <!-- <MaintenanceDialogComponent :dialogState.sync="downloadDialog" :title="dialogTitle" /> -->

    <v-dialog v-model="uploadDialog" width="auto">
      <v-card class="px-10 py-10">
        <v-layout row wrap justify-space-between align-center>
          <h1 style="font-size: 1.5rem; font-weight: 700">Unggah ATT6 Ruas CTP</h1>
          <img
              style="cursor: pointer"
              @click="uploadDialog = false"
              :src="require('../assets/icons/ph_x-bold.svg')"
          />
        </v-layout>
        <div
            class="dropzone-container mt-10"
            @dragover.prevent="dragover"
            @dragleave.prevent="dragleave"
            @drop.prevent="drop"
            ref="dropzone"
        >
          <input
              type="file"
              name="file"
              id="fileInput"
              class="hidden-input"
              @change="onChange"
              ref="fileInput"
              accept=".xlsx, .xls"
          />

          <label
              for="fileInput"
              class="file-label"
              style="color: #abb5be; font-size: 16px; width: 50vw"
          >
            <div v-if="isDragging">Drag file disini</div>
            <div
                v-else-if="!selectedFile"
                style="display: flex; flex-direction: column"
                class="justify-center align-center"
            >
              <img
                  :src="require('../assets/icons/material-symbols_folder.svg')"
                  style="width: 32px; height: auto"
                  class="mb-1"
              />
              <div style="color: #abb5be; font-size: 16px">
                <u style="color: #004643; font-size: 16px">Cari</u> atau drag
                file untuk unggah ATT6 disini
              </div>
            </div>
            <div class="preview-container flex-column" v-if="selectedFile">
              <div
                  class="preview-card flex-row justify-center align-center mx-auto"
              >
                <span>{{ selectedFile.name }}</span>
                <v-btn icon @click.prevent="clearSelectedFile">
                  <v-icon color="red">mdi-close</v-icon>
                </v-btn>
              </div>
              <v-btn
                  color="green"
                  class="ma-2 white--text mx-auto"
                  @click="uploadFile"
              >
                Upload
                <v-icon right dark> mdi-cloud-upload</v-icon>
              </v-btn>
            </div>
          </label>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="downloadDialog" max-width="500px" persistent>
      <v-card class="elevation-10">
        <v-card-title
            class="px-6 py-4 white"
            style="font-size: 16px; font-weight: 400"
        >
          Pilih Rentang Waktu Laporan
        </v-card-title>

        <v-card-text class="pa-0">
          <div class="px-4 py-2">
            <DateFilterComponent
                @update:startDate="updateStartDate"
                @update:endDate="updateEndDate"
            />
          </div>
        </v-card-text>

        <v-card-actions class="pa-6">
          <v-spacer></v-spacer>
          <v-btn
              elevation="0"
              class="text-capitalize text-body-2 white--text rounded-lg px-3 py-1"
              color="red"
              @click="downloadDialog = false"
              :disabled="loading"
          >
            <span class="white--text">Kembali</span>
          </v-btn>
          <v-btn
              elevation="0"
              class="text-capitalize text-body-2 white--text rounded-lg px-3 py-1"
              color="#004643"
              @click="showDownloadDialog"
              :loading="loading"
          >
            <span class="white--text">Download Laporan</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="previewDialog" max-width="75vw">
      <v-card>
        <v-card-title class="headline d-flex justify-space-between">
          <p
              class="pa-0 ma-0"
              style="font-size: 16px; font-weight: 500"
          >
            Preview Laporan
          </p>
          <div>
            <v-btn icon @click="downloadPdf" class="mr-2" color="primary">
              <v-icon>mdi-download</v-icon>
            </v-btn>
            <v-btn icon @click="previewDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-card-text>
          <div style="height: 75vh">
            <embed
                :src="pdfUrl"
                type="application/pdf"
                width="100%"
                height="100%"
            />
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
