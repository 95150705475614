<script>
export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  methods: {
    scrollLeft() {
      const container = this.$refs.scrollContainer.$el.querySelector(
        ".v-data-table__wrapper"
      );
      if (container) {
        container.scrollBy({
          left: -200,
          behavior: "smooth",
        });
      }
    },
    scrollRight() {
      const container = this.$refs.scrollContainer.$el.querySelector(
        ".v-data-table__wrapper"
      );
      if (container) {
        container.scrollBy({
          left: 200,
          behavior: "smooth",
        });
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.getDate().toString();
    },
    formattedAverage(value) {
      const resultValue = Number(value);
      if (!isNaN(resultValue)) {
        const roundedValue = resultValue.toFixed(0);
        return new Intl.NumberFormat('id-ID').format(roundedValue);
      }
      return value;
    },
    formattedNumber(value) {
      const resultValue = Number(value);
      if (!isNaN(resultValue)) {
        const roundedValue = resultValue.toFixed(0);
        return new Intl.NumberFormat('id-ID').format(roundedValue);
      }
      return value;
    },
    calculateTotal() {
      return this.data.reduce((sum, item) => sum + item.total, 0);
    },
  },
};
</script>

<template>
  <div class="mb-8">
    <div class="d-flex justify-space-between" justify="center">
      <v-simple-table
        ref="scrollContainer"
        style="overflow-x: auto; width: 100%"
      >
        <template v-slot:default>
          <thead>
            <tr style="background-color: #f9bc60">
              <th class="text-center">#</th>
              <th
                class="text-center"
                v-for="(item, index) in data"
                :key="`header-${index}`"
              >
                {{
                  new Date(item.date).toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "short",
                  })
                }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-center text-uppercase">TVL ALL<br />SEGMEN</td>
              <td
                v-for="(item, index) in data"
                :key="`sum-${index}`"
                class="table-cell text-center"
              >
                {{ formattedNumber(item.total) }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <div class="px-8" style="position: relative; z-index: 10">
        <v-btn
          class="rounded-lg"
          style="
            background-color: white;
            position: absolute;
            right: 0px;
            top: 0%;
            border: 1px solid #004643;
            height: 45px;
            width: 45px;
            padding-left: 10px;
            padding-right: 10px;
          "
          icon
          @click="scrollRight"
        >
          <v-icon style="color: #004643">mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn
          class="rounded-lg"
          style="
            background-color: white;
            position: absolute;
            right: 0px;
            bottom: 0%;
            border: 1px solid #004643;
            height: 45px;
            width: 45px;
            padding-left: 10px;
            padding-right: 10px;
          "
          icon
          @click="scrollLeft"
        >
          <v-icon style="color: #004643">mdi-chevron-left</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<style scoped>
.table-cell {
  border-right: 1.5px solid #e8e4e6;
}

tr td:first-child {
  border-right: 1.5px solid #e8e4e6;
}

.table-cell:last-child {
  border-right: none;
}

/* Add this to ensure the table wrapper is scrollable */
:deep(.v-data-table__wrapper) {
  overflow-x: auto;
  scroll-behavior: smooth;
}
</style>
