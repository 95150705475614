<template>
  <div class="px-10 py-5">
    <v-card elevation="0">
      <div class="d-flex align-center justify-space-between mb-6">
        <h3
          class="text--black text-uppercase"
          style="font-size: 18px; font-weight: 500"
        >
          GRAFIK DISTRIBUSI PER GOLONGAN
        </h3>
        <div class="d-flex">
          <v-select
            outlined
            multiple
            hide-details
            v-model="selectedGolongan"
            :items="golonganItems"
            item-text="title"
            item-value="value"
            label="Golongan"
            class="mr-4 rounded-lg"
            :menu-props="{
              location: 'bottom',
              origin: 'top center',
              transition: 'slide-y-transition',
              offsetY: true,
            }"
          >
            <template v-slot:selection="{ index }">
              <span class="mr-3" style="font-size: 14px" v-if="index < 1"
                >Golongan</span
              >
              <div
                v-if="index === 0"
                class="circle-chip"
                :style="{
                  fontSize: '14px',
                  border: '1px solid #E16162',
                  color: 'white',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }"
              >
                {{ selectedGolongan.length }}
              </div>
            </template>
            <template v-slot:item="{ item }">
              <span style="font-size: 14px">{{ item.title }}</span>
            </template>
          </v-select>

          <v-select
            outlined
            v-model="selectedPeriod"
            :items="periodItems"
            item-text="title"
            item-value="value"
            label="Periode"
            hide-details
            class="rounded-lg"
            :menu-props="{
              location: 'bottom',
              origin: 'top center',
              transition: 'slide-y-transition',
              offsetY: true,
            }"
          >
            <template v-slot:selection="{ item }">
              <span style="font-size: 14px">{{ item.title }}</span>
            </template>
            <template v-slot:item="{ item }">
              <span style="font-size: 14px">{{ item.title }}</span>
            </template>
          </v-select>
        </div>
      </div>

      <!-- Chart Container -->
      <div
        class="py-6 chart-container"
        style="position: relative; height: 400px"
      >
        <canvas ref="trafficChart"></canvas>
      </div>

      <!-- Description -->
      <div class="d-flex justify-center mt-4">
        <div
          v-for="item in golonganItems.filter((item) =>
            selectedGolongan.includes(item.value)
          )"
          :key="item.value"
          class="mx-3 d-flex align-center"
        >
          <div
            class="mr-1"
            style="width: 16px; height: 16px"
            :style="{ backgroundColor: descriptionColors[item.value] }"
          ></div>
          <span style="font-size: 12px; font-weight: 300">{{
            item.title
          }}</span>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import * as constant from "@/constants/constant";

export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      golonganItems: constant.groups,
      periodItems: constant.periods,
      selectedGolongan: [],
      selectedPeriod: "",
      chart: null,
      descriptionColors: {
        1: "#81c784",
        2: "#9fa8da",
        3: "#ffab91",
        4: "#fff59d",
        5: "#ce93d8",
      },
    };
  },
  watch: {
    selectedGolongan: {
      handler() {
        this.updateChart();
      },
      immediate: true,
    },
    selectedPeriod() {
      this.emitDateUpdates();
    },
    data() {
      this.updateChart();
    },
  },
  computed: {
    processedData() {
      if (!this.data || this.data.length === 0) {
        return { labels: [], datasets: [] };
      }

      const labels = this.data.map((item) => {
        const date = new Date(item.date);
        const monthName = date.toLocaleString("default", { month: "short" });
        return `${date.getDate()} ${monthName}`;
      });

      const datasets = this.golonganItems
        .filter((groupItem) => this.selectedGolongan.includes(groupItem.value))
        .map((groupItem) => {
          const data = this.data.map((itemData) => {
            const detail = itemData.group_value.find(
              (detailItem) => detailItem.group === parseInt(groupItem.value)
            );
            return detail ? detail.value : 0;
          });
          return {
            label: groupItem.title,
            data,
            backgroundColor: this.descriptionColors[groupItem.value],
          };
        });

      return { labels, datasets };
    },
  },
  mounted() {
    this.initChart();
    this.emitDateUpdates();
  },
  beforeMount() {
    this.selectedGolongan = ["1", "2", "3", "4", "5"];
    this.selectedPeriod = "daily";
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.destroy();
    }
  },
  methods: {
    emitDateUpdates() {
      this.$emit("update:selectedGolongan", this.selectedGolongan);
      this.$emit("update:selectedPeriod", this.selectedPeriod);
    },
    initChart() {
      if (this.chart) {
        this.chart.destroy();
      }

      const ctx = this.$refs.trafficChart.getContext("2d");
      this.chart = new Chart(ctx, {
        type: "bar",
        data: this.processedData || {
          labels: [],
          datasets: [],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              stacked: true,
              grid: {
                display: true,
                color: "#f0f0f0",
              },
            },
            y: {
              stacked: true,
              grid: {
                display: true,
                color: "#f0f0f0",
              },
              ticks: {
                callback: function (value) {
                  return value.toLocaleString();
                },
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
        },
      });
    },
    updateChart() {
      if (this.chart) {
        this.chart.data.labels = this.processedData.labels;
        this.chart.data.datasets = this.processedData.datasets;
        this.chart.update();
      }
    },
  },
};
</script>

<style scoped>
.v-select {
  max-width: 200px;
}

.circle-chip {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  background-color: #e16162;
}
</style>
