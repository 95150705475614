<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      legends: [
        { name: "Off Ramp", color: "orange" },
        { name: "Entrance", color: "teal" },
        { name: "Exit", color: "red" },
      ],
    };
  },
  methods: {
    formattedAverage(value) {
      const resultValue = Number(value);
      if (!isNaN(resultValue)) {
        const roundedValue = resultValue.toFixed(0);
        return new Intl.NumberFormat('id-ID').format(roundedValue);
      }
      return value;
    },
    formattedNumber(value) {
      const resultValue = Number(value);
      if (!isNaN(resultValue)) {
        const roundedValue = resultValue.toFixed(0);
        return new Intl.NumberFormat('id-ID').format(roundedValue);
      }
      return value;
    },
    getColorByType(type) {
      switch (type) {
        case "off_ramp":
          return "orange";
        case "entrance":
          return "teal";
        case "exit":
          return "red";
        default:
          return "grey";
      }
    },
    scrollLeft() {
      const container = this.$refs.scrollContainer;
      if (container) {
        container.scrollLeft -= 200;
        container.behavior = "smooth";
      }
    },
    scrollRight() {
      const container = this.$refs.scrollContainer;
      if (container) {
        container.scrollLeft += 200;
        container.behavior = "smooth";
      }
    },
  },
};
</script>

<template>
  <div class="py-4 px-10">
    <v-card class="pt-5 pb-3 rounded-xl" outlined>
      <v-row no-gutters class="text-center">
        <v-col class="text-uppercase">
          <h3 style="font-size: 14px; font-weight: 600">
            Volume Lalu Lintas Rata-Rata
          </h3>
          <h1 style="font-size: 42px; font-weight: 500">{{ formattedNumber(data.total) }}</h1>
          <h3 style="font-size: 14px; font-weight: 600; color: #a3a3a3">
            Kendaraan
          </h3>
        </v-col>
        <v-col class="text-uppercase">
          <h3 style="font-size: 14px; font-weight: 600">
            Jumlah Volume Lalu Lintas Harian
          </h3>
          <h1 style="font-size: 42px; font-weight: 500">{{ formattedAverage(data.average) }}</h1>
          <h3 style="font-size: 14px; font-weight: 600; color: #a3a3a3">
            Kendaraan
          </h3>
        </v-col>
      </v-row>

      <v-divider class="my-4"></v-divider>

      <v-row no-gutters justify="center" align="center">
        <div
          v-for="(legend, index) in legends"
          :key="index"
          class="mx-4"
          outlined
        >
          <v-avatar left size="8" class="mr-2" :color="legend.color"></v-avatar>
          <span style="font-size: 12px">{{ legend.name }}</span>
        </div>
      </v-row>

      <v-row
        no-gutters
        class="py-1 position-relative"
        justify="center"
        align="center"
        style="position: relative"
      >
        <v-btn
          class="px-3 rounded-lg"
          style="
            background-color: white;
            position: absolute;
            left: 20px;
            top: 50%;
            transform: translateY(-50%);
            border: 1px solid #004643;
            height: 45px;
            width: 45px;
            padding-left: 10px;
            padding-right: 10px;
          "
          icon
          @click="scrollLeft"
        >
          <v-icon style="color: #004643">mdi-chevron-left</v-icon>
        </v-btn>

        <div
          ref="scrollContainer"
          class="d-flex align-center"
          style="overflow-x: scroll; max-width: 90%; scroll-behavior: smooth"
        >
          <v-col
            cols="auto"
            v-for="(data, index) in data.detail_per_gates"
            :key="index"
          >
            <div
              outlined
              class="d-flex align-center py-3 px-5 rounded-lg"
              :style="{
                borderColor: getColorByType(data.type),
                borderWidth: '1px',
                borderStyle: 'solid',
              }"
            >
              <v-avatar
                class="mr-3"
                size="10"
                :color="getColorByType(data.type)"
              ></v-avatar>
              <div>
                <h5 style="font-size: 16px; font-weight: 400">
                  {{ data.gate }}
                </h5>
                <p
                  class="pt-1 pr-10 ma-0"
                  style="font-size: 14px; font-weight: 400; color: #a3a3a3"
                >
                  {{ formattedNumber(data.total) }} Kendaraan
                </p>
              </div>
            </div>
          </v-col>
        </div>

        <v-btn
          class="px-3 rounded-lg"
          style="
            background-color: white;
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
            border: 1px solid #004643;
            height: 45px;
            width: 45px;
            padding-left: 10px;
            padding-right: 10px;
          "
          icon
          @click="scrollRight"
        >
          <v-icon style="color: #004643">mdi-chevron-right</v-icon>
        </v-btn>
      </v-row>
    </v-card>
  </div>
</template>
