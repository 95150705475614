<template>
  <v-container class="py-5" style="max-width: 90vw">
    <title-component :title="title" />
    <breadcrumbs-component :crumbs="crumbs" />

    <date-filter-component
      :date-from="date1"
      :date-to="date2"
      :func="recallData"
    />

    <v-row>
      <v-col>
        <div
          class="my-2 px-3 rounded-xl"
          style="box-shadow: 0px 2px 10px 0px #e8e4e67d"
        >
          <v-layout row wrap justify-space-between align-center class="pa-5">
            <h2
              class="font-weight-bold"
              style="font-size: 1rem; font-weight: 700"
            >
              Inspeksi dan Temuan
              <span style="font-size: 1.5rem; color: #004643">
                {{ percentage }}%
              </span>
            </h2>
            <v-col cols="3" class="pa-0">
              <v-select
                :items="[
                  { label: 'Harian', type: 'day' },
                  { label: 'Mingguan', type: 'week' },
                  { label: 'Bulanan', type: 'month' },
                ]"
                v-model="type"
                item-text="label"
                item-value="type"
                dense
                solo
                height="10"
                hide-details
                @input="recallData"
              ></v-select>
            </v-col>
          </v-layout>

          <div id="chart">
            <apexchart
              type="line"
              height="350"
              ref="realtimeChart"
              :options="chartOptions"
              :series="series"
            ></apexchart>
          </div>
        </div>
      </v-col>
      <v-col>
        <div
          class="my-2 px-3 rounded-xl"
          style="box-shadow: 0px 2px 10px 0px #e8e4e67d"
        >
          <v-layout row wrap justify-space-between align-center class="pa-5">
            <h2
              class="font-weight-bold"
              style="font-size: 1rem; font-weight: 700"
            >
              Insiden dan Aset Rusak
              <span style="font-size: 1.5rem; color: #e16162">
                {{ percentage2 }}%
              </span>
            </h2>
            <v-col cols="3" class="pa-0">
              <v-select
                :items="[
                  { label: 'Harian', type: 'day' },
                  { label: 'Mingguan', type: 'week' },
                  { label: 'Bulanan', type: 'month' },
                ]"
                v-model="type"
                item-text="label"
                item-value="type"
                dense
                solo
                height="10"
                hide-details
                @input="recallData"
              ></v-select>
            </v-col>
          </v-layout>

          <div id="chart2">
            <apexchart
              type="line"
              height="350"
              ref="realtimeChart2"
              :options="chartOptions2"
              :series="series2"
            ></apexchart>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-tabs v-model="activeTab" @change="fetchData">
      <v-tab href="#inisiasi">Inisiasi</v-tab>
      <v-tab-item :key="1" value="inisiasi" :transition="false">
        <table-component
          tableTitle="Laporan Inisiasi"
          :headers="tableHeaders"
          :items="computedInitialReports"
          :downloadFunc="downloadInisiasi"
        />
      </v-tab-item>

      <v-tab href="#tindak-lanjut">Tindak Lanjut</v-tab>
      <v-tab-item :key="2" value="tindak-lanjut" :transition="false">
        <table-component
          tableTitle="Laporan Tindak Lanjut"
          :headers="tableHeaders"
          :items="computedFollowUpReports"
          :downloadFunc="downloadTindakLanjut"
        />
      </v-tab-item>

      <v-tab href="#final">Final</v-tab>
      <v-tab-item :key="3" value="final" :transition="false">
        <table-component
          tableTitle="Laporan Final"
          :headers="tableHeadersFinal"
          :items="computedFinalReports"
          :downloadFunc="downloadFinal"
        />
      </v-tab-item>
    </v-tabs>
    <v-snackbar v-model="snackbar" :timeout="timeout" shaped top color="white">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="#004643" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import * as constant from "@/constants/constant";
import TitleComponent from "@/components/TitleComponent.vue";
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue";
import DateFilterComponent from "@/components/DateFilterComponent.vue";
import TableComponent from "@/components/TableComponent.vue";
import VueApexCharts from "vue-apexcharts";
import { formatKilometer } from "@/plugins/helpers";

export default {
  components: {
    apexchart: VueApexCharts,
    TitleComponent,
    BreadcrumbsComponent,
    DateFilterComponent,
    TableComponent,
  },
  beforeMount() {
    this.init();
  },
  methods: {
    async init() {
      const params = {
        from: this.date1,
        to: this.date2,
      };
      const response = await this.$axios.get("/all-initial-reports", {
        params,
      });
      this.initialReports = response.data.data.initial_reports;
      this.startCountdownForReports(
        this.initialReports,
        this.initialReportCountdowns
      );
      await this.updateChartData(
        "realtimeChart",
        this.chartOptions,
        this.series,
        this.percentage,
        "/chart-inspection-and-findings"
      );
      await this.updateChartData(
        "realtimeChart2",
        this.chartOptions2,
        this.series2,
        this.percentage2,
        "/chart-incident-and-damaged-assets"
      );
    },
    async fetchData() {
      const params = {
        from: this.date1,
        to: this.date2,
      };
      if (this.activeTab === "tindak-lanjut") {
        const response = await this.$axios.get("/all-follow-up-reports", {
          params,
        });
        this.followUpReports = response.data.data.follow_up_reports;
        this.startCountdownForReports(
          this.followUpReports,
          this.followUpReportCountdowns
        );
      } else if (this.activeTab === "final") {
        const response = await this.$axios.get("/all-final-reports", {
          params,
        });
        this.finalReports = response.data.data.final_reports;
        this.finalReports.forEach((report) => {
          report.completedTime = this.calculateTimeDifference(
            report.initial_created_at,
            report.final_created_at
          );
        });
        this.finalReports.this.startCountdownForReports(
          this.finalReports,
          this.finalReportCountdowns
        );
      } else if (this.activeTab === "inisiasi") {
        const response = await this.$axios.get("/all-initial-reports", {
          params,
        });
        this.initialReports = response.data.data.initial_reports;
        this.startCountdownForReports(
          this.initialReports,
          this.initialReportCountdowns
        );
      }
    },
    startCountdownForReports(reports, countdowns) {
      reports.forEach((report) => {
        if (report.asset_category_id != 3) {
          this.$set(countdowns, report.id, "-");
          return;
        }
        const createdAt = new Date(report.created_at);
        let deadline;
        if (![6, 7, 8].includes(report.asset_sub_category_id)) {
          deadline = constant.deadlines[report.asset_sub_category_id] * 3600;
        } else {
          deadline =
            constant.deadlines[report.asset_advance_category_id] * 3600;
        }
        setInterval(() => {
          const currentTime = new Date();
          const targetTime = new Date(createdAt.getTime() + deadline * 1000);
          const timeDiff = targetTime - currentTime;
          if (timeDiff < 0) {
            const hours = Math.floor(timeDiff / 3600000);
            const minutes = Math.floor((timeDiff % 3600000) / 60000) * -1;
            const seconds = Math.floor((timeDiff % 60000) / 1000) * -1;
            this.$set(
              countdowns,
              report.id,
              `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
                2,
                "0"
              )}:${String(seconds).padStart(2, "0")}`
            );
          } else {
            const hours = Math.floor(timeDiff / 3600000);
            const minutes = Math.floor((timeDiff % 3600000) / 60000);
            const seconds = Math.floor((timeDiff % 60000) / 1000);
            this.$set(
              countdowns,
              report.id,
              `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
                2,
                "0"
              )}:${String(seconds).padStart(2, "0")}`
            );
          }
        }, 1000);
      });
    },
    calculateTimeDifference(initialCreatedAt, createdAt) {
      const initialDate = new Date(initialCreatedAt);
      const endDate = new Date(createdAt);

      let timeDifferenceInMillis = endDate - initialDate;

      const isNegative = timeDifferenceInMillis < 0;
      if (isNegative) {
        timeDifferenceInMillis *= -1;
      }

      const hours = Math.floor(timeDifferenceInMillis / (1000 * 60 * 60));
      const minutes = Math.floor(
        (timeDifferenceInMillis % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((timeDifferenceInMillis % (1000 * 60)) / 1000);

      const formattedTime = `${
        (isNegative ? "-" : "") + String(hours).padStart(2, "0")
      }:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
        2,
        "0"
      )}`;

      return formattedTime;
    },
    generateReportProperties(reports, countdowns, pathPrefix) {
      return reports.map((report) => ({
        ...report,
        asset_category: constant.assetCategories[report.asset_category_id],
        asset_sub_category:
          constant.assetSubCategories[report.asset_sub_category_id] || "-",
        asset_advance_category: constant.assetAdvanceCategories[
          report.asset_advance_category_id
        ] || "-",
        countdown: countdowns[report.id] || "00:00:00",
        href: `/${pathPrefix}/${report.id}`,
        source: constant.sources[report.source],
        location: formatKilometer(report.location)
      }));
    },
    async updateChartData(
      chartType,
      chartOptions,
      chartData,
      percentage,
      apiEndpoint
    ) {
      const response = await this.$axios.post(apiEndpoint, {
        type: this.type,
        date_start: this.date1,
        date_end: this.date2,
      });

      if (chartType === "realtimeChart") {
        this.percentage = response.data.percentage;
        this.chartOptions.xaxis.categories = response.data.categories;
        chartData[0].data = response.data["x-data-findings"];
        chartData[1].data = response.data["x-data-inspections"];
        if (this.$refs[chartType]) {
          this.$refs[chartType].updateSeries(this.series);
          this.$refs[chartType].updateOptions(this.chartOptions);
        }
      } else if (chartType === "realtimeChart2") {
        this.percentage2 = response.data.percentage;
        this.chartOptions2.xaxis.categories = response.data.categories;
        chartData[0].data = response.data["x-data-incidents"];
        chartData[1].data = response.data["x-data-damaged-assets"];
        if (this.$refs[chartType]) {
          this.$refs[chartType].updateSeries(this.series2);
          this.$refs[chartType].updateOptions(this.chartOptions2);
        }
      }
    },
    getInitialDate() {
      const currentDate = new Date();

      const firstDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      firstDate.setDate(firstDate.getDate() + 1);

      const lastDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      );
      lastDate.setDate(lastDate.getDate() + 1);

      const formattedFirstDate = firstDate.toISOString()?.slice(0, 10);
      const formattedLastDate = lastDate.toISOString()?.slice(0, 10);

      return {
        date_start: formattedFirstDate,
        date_end: formattedLastDate,
      };
    },
    async recallData(dateFrom, dateTo) {
      if (dateFrom && dateTo) {
        this.date1 = dateFrom;
        this.date2 = dateTo;
      }

      await this.updateChartData(
        "realtimeChart",
        this.chartOptions,
        this.series,
        this.percentage,
        "/chart-inspection-and-findings"
      );

      await this.updateChartData(
        "realtimeChart2",
        this.chartOptions2,
        this.series2,
        this.percentage2,
        "/chart-incident-and-damaged-assets"
      );

      await this.fetchData();
    },
    async downloadInisiasi() {
      const queryParams = {
        from: this.date1,
        to: this.date2,
      };

      try {
        const response = await this.$axios.get("/download-initial-reports", {
          params: queryParams,
        });
        const mediaType =
          "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
        const base64Data = response.data.excelData;

        const url = mediaType + base64Data;

        const a = document.createElement("a");
        const formattedDate1 = `${this.date1.slice(9, 11)}${this.date1.slice(
          5,
          7
        )}${this.date1.slice(0, 4)}`;
        const formattedDate2 = `${this.date2.slice(9, 11)}${this.date2.slice(
          5,
          7
        )}${this.date2.slice(0, 4)}`;
        const fileName = `Laporan Inisiasi_${formattedDate1}_${formattedDate2}.xlsx`;
        a.href = url;
        a.download = fileName;
        await a.click();
        window.URL.revokeObjectURL(url);

        this.message = "Berhasil mendownload data";
        this.snackbar = true;
      } catch (error) {
        this.message = "Gagal mendownload data";
        this.snackbar = true;
        console.error("Error downloading file:", error);
      }
    },
    async downloadTindakLanjut() {
      const queryParams = {
        from: this.date1,
        to: this.date2,
      };

      try {
        const response = await this.$axios.get("/download-follow-up-reports", {
          params: queryParams,
        });
        const mediaType =
          "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
        const base64Data = response.data.excelData;

        const url = mediaType + base64Data;

        const a = document.createElement("a");
        const formattedDate1 = `${this.date1.slice(9, 11)}${this.date1.slice(
          5,
          7
        )}${this.date1.slice(0, 4)}`;
        const formattedDate2 = `${this.date2.slice(9, 11)}${this.date2.slice(
          5,
          7
        )}${this.date2.slice(0, 4)}`;
        const fileName = `Laporan Tindak Lanjut_${formattedDate1}_${formattedDate2}.xlsx`;
        a.href = url;
        a.download = fileName;
        await a.click();
        window.URL.revokeObjectURL(url);

        this.message = "Berhasil mendownload data";
        this.snackbar = true;
      } catch (error) {
        this.message = "Gagal mendownload data";
        this.snackbar = true;
        console.error("Error downloading file:", error);
      }
    },
    async downloadFinal() {
      const queryParams = {
        from: this.date1,
        to: this.date2,
      };

      try {
        const response = await this.$axios.get("/download-final-reports", {
          params: queryParams,
        });
        const mediaType =
          "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
        const base64Data = response.data.excelData;

        const url = mediaType + base64Data;

        const a = document.createElement("a");
        const formattedDate1 = `${this.date1.slice(9, 11)}${this.date1.slice(
          5,
          7
        )}${this.date1.slice(0, 4)}`;
        const formattedDate2 = `${this.date2.slice(9, 11)}${this.date2.slice(
          5,
          7
        )}${this.date2.slice(0, 4)}`;
        const fileName = `Laporan Final_${formattedDate1}_${formattedDate2}.xlsx`;
        a.href = url;
        a.download = fileName;
        await a.click();
        window.URL.revokeObjectURL(url);

        this.message = "Berhasil mendownload data";
        this.snackbar = true;
      } catch (error) {
        this.message = "Gagal mendownload data";
        this.snackbar = true;
        console.error("Error downloading file:", error);
      }
    },
  },
  computed: {
    computedInitialReports() {
      return this.generateReportProperties(
        this.initialReports,
        this.initialReportCountdowns,
        "layanan-pemeliharaan/inisiasi"
      );
    },
    computedFollowUpReports() {
      return this.generateReportProperties(
        this.followUpReports,
        this.followUpReportCountdowns,
        "layanan-pemeliharaan/tindak-lanjut"
      );
    },
    computedFinalReports() {
      return this.generateReportProperties(
        this.finalReports,
        this.finalReportCountdowns,
        "layanan-pemeliharaan/final"
      );
    },
  },
  data() {
    const initialDate = this.getInitialDate();
    return {
      snackbar: false,
      message: "",
      timeout: 3000,
      percentage: 0,
      percentage2: 0,
      type: "month",
      date1: initialDate.date_start,
      date2: initialDate.date_end,
      activeTab: "inisiasi",
      title: "Layanan Pemeliharaan",
      series: [
        {
          name: "Temuan",
          data: [],
        },
        {
          name: "Inspeksi",
          data: [],
        },
      ],
      series2: [
        {
          name: "Insiden",
          data: [],
        },
        {
          name: "Aset Rusak",
          data: [],
        },
      ],
      chartOptions: {
        noData: {
          text: "Loading...",
        },
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
        markers: {
          size: 5,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        grid: {
          row: {
            colors: ["transparent"],
          },
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          min: 0,
          forceNiceScale: true,
          labels: {
            formatter: function (value) {
              return Math.round(value);
            },
          },
        },
        colors: ["#A370F7", "#20C997"],
      },
      chartOptions2: {
        noData: {
          text: "Loading...",
        },
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
        markers: {
          size: 5,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        grid: {
          row: {
            colors: ["transparent"],
          },
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          min: 0,
          forceNiceScale: true,
          labels: {
            formatter: function (value) {
              return Math.round(value);
            },
          },
        },
        colors: ["#0DCAF0", "#E35D6A"],
      },
      tableHeaders: [
        {
          text: "Waktu Laporan",
          align: "start",
          value: "created_at",
        },
        {
          text: "Nomor Laporan",
          align: "start",
          value: "report_number",
        },
        {
          text: "Nama Pelapor",
          align: "start",
          value: "reporter",
        },
        { text: "Kategori Utama", value: "asset_category" },
        { text: "Sub Kategori", value: "asset_sub_category" },
        { text: "Kategori Lanjutan", value: "asset_advance_category" },
        { text: "Lokasi Observasi", value: "location" },
        { text: "Sumber Laporan", value: "source" },
        { text: "Sisa Waktu Penyelesaian", value: "countdown" },
        {
          text: "",
          value: "arrow",
          sortable: false,
          filterable: false,
          width: "50px",
          align: "center",
        },
      ],
      tableHeadersFollowUp: [
        {
          text: "Waktu Laporan",
          align: "start",
          value: "created_at",
        },
        {
          text: "Nomor Laporan",
          align: "start",
          value: "report_number",
        },
        { text: "Kategori Utama", value: "asset_category" },
        { text: "Sub Kategori", value: "asset_sub_category" },
        { text: "Lokasi Observasi", value: "location" },
        { text: "Sisa Waktu Penyelesaian", value: "countdown" },
        {
          text: "",
          value: "arrow",
          sortable: false,
          filterable: false,
          width: "50px",
          align: "center",
        },
      ],
      tableHeadersFinal: [
        {
          text: "Waktu Laporan",
          align: "start",
          value: "created_at",
        },
        {
          text: "Nomor Laporan",
          align: "start",
          value: "report_number",
        },
        {
          text: "Nama Pelapor",
          align: "start",
          value: "reporter",
        },
        { text: "Kategori Utama", value: "asset_category" },
        { text: "Sub Kategori", value: "asset_sub_category" },
        { text: "Kategori Lanjutan", value: "asset_advance_category" },
        { text: "Lokasi Observasi", value: "location" },
        { text: "Waktu Selesai Laporan", value: "completedTime" },
        {
          text: "",
          value: "arrow",
          sortable: false,
          filterable: false,
          width: "50px",
          align: "center",
        },
      ],
      initialReports: [],
      followUpReports: [],
      finalReports: [],
      initialReportCountdowns: {},
      followUpReportCountdowns: {},
      finalReportCountdowns: {},
      crumbs: [
        {
          text: "Layanan Pemeliharaan",
          disabled: true,
          href: "/layanan-pemeliharaan",
        },
      ],
    };
  },
};
</script>

<style></style>
