<script>
import * as constant from "@/constants/constant";
import Cookies from "js-cookie";

export default {
  computed: {
    constant() {
      return constant
    }
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    const account = JSON.parse(Cookies.get("loginResponse"));

    return {
      account: account,
      yearList: [],
      anggaranData: {},
      historyData: [],
      updateDialog: false,
      selectedYearMenu: false,
      selectedYear: null,
      form: {
        rkap: "",
        ppjt: "",
      },
      snackbar: {
        show: false,
        message: "",
        color: "success",
      },
    };
  },
  mounted() {
    this.fetchDataCurrentYear();
    this.generateYearList();
  },
  watch: {
    updateDialog(newVal) {
      if (newVal) {
        this.fetchDataAllYear();
      }
    },
  },
  methods: {
    generateYearList() {
      const currentYear = new Date().getFullYear();
      const startYear = currentYear - 20;
      this.yearList = [];
      for (let year = currentYear; year >= startYear; year--) {
        this.yearList.push(year.toString());
      }
    },
    formattedAverage(value) {
      const resultValue = Number(value);
      if (!isNaN(resultValue)) {
        const roundedValue = resultValue.toFixed(0);
        return new Intl.NumberFormat('id-ID').format(roundedValue);
      }
      return value;
    },
    formattedNumber(value) {
      const resultValue = Number(value);
      if (!isNaN(resultValue)) {
        const roundedValue = resultValue.toFixed(0);
        return new Intl.NumberFormat('id-ID').format(roundedValue);
      }
      return value;
    },
    getColorByType(type) {
      switch (type) {
        case 1:
          return "orange";
        case 2:
          return "teal";
        case 3:
          return "red";
        case 4:
          return "blue";
        case 5:
          return "purple";
        default:
          return "grey";
      }
    },
    scrollLeft() {
      const container = this.$refs.scrollContainer;
      if (container) {
        container.scrollLeft -= 200;
        container.behavior = "smooth";
      }
    },
    scrollRight() {
      const container = this.$refs.scrollContainer;
      if (container) {
        container.scrollLeft += 200;
        container.behavior = "smooth";
      }
    },
    showUpdateDialog() {
      // this.updateDialog = false;
      this.fetchUpdateData();
    },
    async fetchDataCurrentYear() {
      try {
        const response = await this.$axios.get("rkat-ppjt/current-year");
        this.anggaranData = response.data.data;
      } catch (error) {
        console.error("Error fetching current year data:", error);
      }
    },
    async fetchDataAllYear() {
      try {
        const response = await this.$axios.get("rkat-ppjt");
        this.historyData = response.data.data;
      } catch (error) {
        console.error("Error fetching all years data:", error);
      }
    },
    async fetchUpdateData() {
      try {
        const response = await this.$axios.put(
            `rkat-ppjt/${this.selectedYear}`,
            {
              rkat: parseFloat(this.form.rkap),
              ppjt: parseFloat(this.form.ppjt),
            }
        );
        const currentYear = new Date().getFullYear();
        if (response.status == 200 && this.selectedYear == currentYear) {
          this.fetchDataCurrentYear();
        }
        this.updateDialog = false;

        // Show success notification
        this.showNotification("Data updated successfully", "success");
      } catch (error) {
        console.error("Error updating data:", error);

        // Show error notification
        this.showNotification(
            "Error updating data. Please try again.",
            "error"
        );
      } finally {
        this.selectedYear = "";
        this.form.rkap = "";
        this.form.ppjt = "";
      }
    },
    showNotification(message, color) {
      this.snackbar.message = message;
      this.snackbar.color = color;
      this.snackbar.show = true;
    },
  },
};
</script>

<template>
  <div class="py-4 px-10">
    <v-card class="rounded-xl" outlined>
      <v-row no-gutters class="pa-5 text-center">
        <v-col class="text-uppercase my-auto">
          <h3 style="font-size: 14px; font-weight: 600">
            Jumlah LHR Tertimbang
          </h3>
          <h1 style="font-size: 42px; font-weight: 500">
            {{ formattedNumber(data.total) }}
          </h1>
          <h3 style="font-size: 16; font-weight: 400; color: #a3a3a3">
            Kendaraan
          </h3>
        </v-col>
        <v-col class="text-uppercase my-auto">
          <h3 style="font-size: 14px; font-weight: 600">
            LHR Tertimbang Rerata
          </h3>
          <h1 style="font-size: 42px; font-weight: 500">
            {{ formattedAverage(data.average) }}
          </h1>
          <h3 style="font-size: 16; font-weight: 400; color: #a3a3a3">
            Kendaraan
          </h3>
        </v-col>
        <v-col>
          <div class="rounded-xl pa-5" style="border: 1.5px solid #e8e4e6">
            <v-row no-gutters class="mb-3">
              <v-col class="mr-2 text-uppercase my-auto">
                <h3 style="font-size: 14px; font-weight: 600">
                  RKAP ({{ anggaranData.year }})
                </h3>
                <h1 style="font-size: 42px; font-weight: 500">
                  {{ formattedNumber(anggaranData.rkat) }}
                </h1>
                <h3 style="font-size: 12px; font-weight: 400; color: #a3a3a3">
                  Kendaraan
                </h3>
              </v-col>
              <v-col class="ml-2 text-uppercase my-auto">
                <h3 style="font-size: 14px; font-weight: 600">
                  PPJT ({{ anggaranData.year }})
                </h3>
                <h1 style="font-size: 42px; font-weight: 500">
                  {{ formattedNumber(anggaranData.ppjt) }}
                </h1>
                <h3 style="font-size: 12px; font-weight: 400; color: #a3a3a3">
                  Kendaraan
                </h3>
              </v-col>
            </v-row>
            <v-btn block
                   :disabled="this.account.scopes.includes(constant.SCOPES.view_transaction)
                && !(this.account.scopes.includes(constant.SCOPES.manage_transaction))"
                   @click="updateDialog = true"
                   class="rounded-lg text-uppercase white--text py-3 p"
                   style="background-color: #004643; cursor: pointer"
            >
              Update
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <v-row no-gutters style="overflow: hidden">
        <v-col
            v-for="(item, index) in data.detail_per_groups"
            :key="index"
            class="pa-5 custom-border"
            :class="{ 'last-child': index === data.detail_per_groups.length - 1 }"
        >
          <div class="d-flex align-center">
            <v-avatar
                class="mr-3"
                size="10"
                :color="getColorByType(index)"
            ></v-avatar>
            <div class="text-uppercase">
              <h5 style="font-size: 12px; font-weight: 600">
                {{ item.label }}
              </h5>
              <h5 class="pr-3" style="font-size: 20px; font-weight: 500">
                {{ formattedNumber(item.total) }}
              </h5>
              <h5 style="font-size: 12px; font-weight: 400; color: #a3a3a3">
                Kendaraan
              </h5>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card>

    <!-- Update Dialog -->
    <v-dialog v-model="updateDialog" max-width="450px">
      <v-card elevation="0" class="rounded-xl">
        <v-card-title
            class="px-6 py-4 white"
            style="font-size: 16px; font-weight: 500"
        >
          HISTORY RKAP dan PPJT
        </v-card-title>

        <v-card-text class="pa-0">
          <!-- History Section -->
          <div
              class="history-section px-6 py-3"
              style="background-color: #fafafa"
          >
            <div v-for="(item, index) in historyData" :key="item.year">
              <p
                  class="pa-0 ma-0"
                  style="color: #bbbbbb; font-size: 16px; font-weight: 400"
              >
                {{ item.year }}
              </p>
              <v-row
                  :class="index === historyData.length - 1 ? 'mb-0' : 'mb-4'"
                  no-gutters
              >
                <v-col>
                  <v-sheet style="background-color: transparent">
                    <p
                        class="pa-0 ma-0"
                        style="font-size: 12px; font-weight: 400"
                    >
                      RKAP
                    </p>
                    <p
                        class="pa-0 ma-0"
                        style="font-size: 14px; font-weight: 500"
                    >
                      {{ formattedNumber(item.rkat) }}
                    </p>
                  </v-sheet>
                </v-col>
                <v-col>
                  <v-sheet style="background-color: transparent">
                    <p
                        class="pa-0 ma-0"
                        style="font-size: 12px; font-weight: 400"
                    >
                      PPJT
                    </p>
                    <p
                        class="pa-0 ma-0"
                        style="font-size: 14px; font-weight: 500"
                    >
                      Rp. {{ formattedNumber(item.ppjt) }}
                    </p>
                  </v-sheet>
                </v-col>
              </v-row>
            </div>
          </div>

          <!-- Update Form Section -->
          <div class="pa-6">
            <p class="pa-0 ma-0" style="font-size: 16px; font-weight: 400">
              UPDATE
            </p>
            <v-form class="mt-5">
              <div class="mb-5">
                <p class="pa-0 ma-0" style="font-size: 12px; font-weight: 400">
                  RKAP
                </p>
                <v-text-field
                    v-model="form.rkap"
                    class="pa-0 ma-0 rounded-lg"
                    outlined
                    hide-details
                    placeholder="Nominal..."
                    type="number"
                >
                  <!-- <template v-slot:prepend-inner>
                    <p
                      class="mt-1 mr-2"
                      style="font-size: 14px; font-weight: 400"
                    >
                      Rp
                    </p>
                  </template> -->
                </v-text-field>
              </div>

              <div class="mb-5">
                <p class="pa-0 ma-0" style="font-size: 12px; font-weight: 400">
                  PPJT
                </p>
                <v-text-field
                    v-model="form.ppjt"
                    class="pa-0 ma-0 rounded-lg"
                    outlined
                    hide-details
                    placeholder="Nominal..."
                    type="number"
                >
                  <!-- <template v-slot:prepend-inner>
                    <p
                      class="mt-1 mr-2"
                      style="font-size: 14px; font-weight: 400"
                    >
                      Rp
                    </p>
                  </template> -->
                </v-text-field>
              </div>

              <v-select
                  v-model="selectedYear"
                  :items="yearList"
                  class="pa-0 ma-0 rounded-lg"
                  outlined
                  hide-details
                  placeholder="Pilih Tahun"
              >
                <template v-slot:prepend-inner>
                  <v-icon class="cursor-pointer pr-2"> mdi-calendar</v-icon>
                </template>
              </v-select>
            </v-form>
          </div>
        </v-card-text>

        <v-card-actions class="pa-6">
          <v-spacer></v-spacer>
          <!-- <v-btn text @click="updateDialog = false"> Kembali </v-btn> -->
          <v-btn
              elevation="0"
              class="text-capitalize text-body-2 white--text rounded-lg px-3 py-1"
              color="red"
              @click="updateDialog = false"
          >
            <span class="white--text">Kembali</span>
          </v-btn>
          <!-- <v-btn color="#9DB2BF" class="white--text" @click="showUpdateDialog">
            Update Data
          </v-btn> -->
          <v-btn
              elevation="0"
              class="text-capitalize text-body-2 white--text rounded-lg px-3 py-1"
              color="#004643"
              @click="showUpdateDialog"
          >
            <span class="white--text">Update Data</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
        v-model="snackbar.show"
        :color="snackbar.color"
        :timeout="3000"
        top
        right
    >
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.show = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<style scoped>
.custom-border {
  border-top: 1px solid #e8e4e6;
  border-right: 1px solid #e8e4e6;
}

.custom-border.last-child {
  border-right: none;
}

.history-section {
  max-height: 300px;
  overflow-y: auto;
}

.v-text-field--outlined >>> fieldset {
  border-color: #abd1c6;
}
</style>
